import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import roi from "../../../assets/images/blog/multi_channel_marketing/maximising_digital_roi-min.png"

export default () => 
<div>
    <SEO title={'How to Improve Your Digital Marketing ROI with a Multi-Channel Strategy'} 
    description="Are you wondering how you may easily improve your digital marketing ROI? Discover the power of a valuable multi-channel strategy in this ICON blog article!" 
    canonical={'https://icon-worldwide.com/blog/how-to-improve-digital-marketing-roi'} />

    <NewsHeader/>

    <div id="single-news">
        <h1>How You Can <span>Improve Your Digital Marketing ROI</span> with a Multi-Channel Strategy </h1>
        <div id="title-underline"></div>
        <p>The combined effect of integrated digital marketing can be best explained by the idea of supporting the customer journey towards a desired goal. For a company, this is about maximising digital return on investment. Although the industry is fragmented between designers, developers, content developers and seo or ppc specialists, this approach fails to approach the individual at the centre of the process. The result of this silo approach is massive inefficiency, loss of the single customer view, and financial waste.</p>
        <h2>Effective Digital Marketing Demands Total Integration</h2>
        
        <img src={roi} style={{width: '100%', display:'block', margin:'50px auto'}} alt="Digital marketing ROI services at ICON Worldwide" title="Digital marketing ROI services, ICON Worldwide"/>

        <div id="single-news-texts">
            <h3>Defining a user goal is one of the first tasks in the information architecture phase of a website development project</h3>
            <p>This goal is then placed at the centre of the graphic design by the User Experience designer. The content of the website is the essential core. The text must be compelling to the target, include key words likely to be used by the target, and hopefully be unique and of a quality that others will want to share, like and link to. This is the beginning of the multichannel marketing process.</p>
            <h3>Social media is likely the first and easiest step in distributing the content.</h3>
            <p>A single article can be used to create multiple social media posts, and can be modified to be more appropriate for each channel. For example, Facebook is likely a different style than the more business like LinkedIn or the more quick-hit style of Twitter.</p>
            <h3>Although the search engines have always stated that there is no correlation between paid and organic search results we maintain there is. </h3>
            <p>And our metrics consistently demonstrate that paid traffic lifts organic. Done consistently over-time, eventually it is possible to reduce the paid levels without harming the organic.</p>
            <p>Using the same key words built into the site content, we can then begin to buy adwords (PPC) in order to force our content in front of those users we are most interested in getting to our site.</p>
            <p>Once the user has clicked on an ad, site cookies will monitor his/her visit to our site. If the user is not compelled to complete the goal task on the first visit then we can engage display advertising, either via a network or on social media, to remarked and remind the user of his interest and hopefully leading them to return to the site and complete the action desired.</p>
            <h3>The point is that each one of these marketing activities is almost wholly ineffective in isolation.</h3>
            <p> It is the combined effect of creating compelling content, optimising the website to ease a path of action, sharing the content in free social channels, paying for placement with the most desired audience, and then guiding them back to the site repeatedly via remarking display advertising.</p>
            <p>This cannot, a debatable opinion, happen without total integration of all channels. Imagine if the process described above doesn’t work, as is often the case in the early stages. The data from each step will provide the holistic view of the customer and likely point to areas for correction. Once identified, adjustments must be made and tested. A single adjustment to a search term/keyword for example, will likely require a content update, a PPC adword shift, etc., until we see that the user is completing the task with a high percentage of success.</p>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta cta-small">LET US HELP YOU</Link></h4>

        <NewsFooter previous="pharma-digital-marketing-case-studies" next="how-gdpr-changed-seo-content-marketing"/>
    </div>

    <Footer noScrollbar="true"/>
</div>